<template>
  <div class="inquiryDetail_page clearfix"> 
    <div class="btn_wrap" style="background:#F2F2F2;" >
        <el-button @click="backRouter" size="small" type="default" >{{$t('back')}}</el-button>
        <el-button @click="modify" size="small" type="primary" >{{$t('modify')}}</el-button>
      </div>
      <div class="myBlock"></div>
    <div class="inuqiryDetail_content">
      <pageTitle :title="$t('cargo')+'  '+ inquiryDetail.inquiry_number">
          <template slot="right">
             <span style="padding-right: 20px;">{{ inquiryDetail.created_time | secondFormat }}</span>
          </template>
      </pageTitle>
      <div class="cargo_detail" v-loading="detailLoading">
        <inquiryDetail v-if="inquiryDetail.id" :info="inquiryDetail"></inquiryDetail>
      </div>
      <div class="myBlock"></div>
      <pageTitle :title="$t('contact')"></pageTitle>
      <div class="contact_detail" v-loading="contactLoading">
        <inquiryContact v-if="inquiryDetail.id" :info="inquiryDetail"></inquiryContact>
      </div>
      
    </div>
  </div>
</template>

<script>
import inquiryDetail from "~bac/components/directOverseas/inquiryDetail/inquiryDetail";
import inquiryContact from "~bac/components/directOverseas/inquiryDetail/inquiryContact";
import pageTitle from "~bac/components/common/pageTitle";
export default {
  components: { inquiryDetail, inquiryContact,pageTitle },
  data() {
    return {
      inquiryDetail: {},
      inquiry_id:null,
      from:null,
      fillout_type:null,
      fillout_id:null,
      statusLoading:false,
      detailLoading:false,
      contactLoading:false,
    };
  },
  created() {
    let queryParams=this._decode(this.$route.query.parameter)
    this.inquiry_id=queryParams.inquiry_id
    this.fillout_type=queryParams.fillout_type
    if (queryParams.from){
      this.from=queryParams.from
    }
    if (queryParams.distribute_user_id){
      this.fillout_id=queryParams.distribute_user_id
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList(){
      this.statusLoading=true
      this.detailLoading=true
      this.contactLoading=true
      let res=await this.$store.dispatch("baseConsole/inquiry_inquiryDetail",{
        inquiry_shipper_id:this.inquiry_id,
        fillout_type:this.fillout_type,
        fillout_id:this.fillout_id
      })
        if (res.success){
          this.statusLoading=false
          this.detailLoading=false
          this.contactLoading=false
          this.inquiryDetail=res.data
      }
    },
    modify() {
      
      this.$router.push({
        path: "/publishInquiry",
        query: {
          parameter: this._encode({
            inquiry_id: this.inquiryDetail.id,
            fillout_type: 1
          })
        }
      });
    },
    backRouter() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="less" scoped>
.inquiryDetail_page {
  background: #fff;
  // padding: 24px 0px;
  .inuqiryDetail_content {
    // width: 1300px;
    margin: 0 auto;
    .cargo_status {
      // box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.15),
      //   0px -1px 7px 0px rgba(0, 0, 0, 0.08);
      border-radius: 2px;
      margin-bottom: 24px;
    }
    .cargo_detail {
      // box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.15),
      //   0px -1px 7px 0px rgba(0, 0, 0, 0.08);
      border-radius: 2px;
      margin-bottom: 24px;
    }
    .contact_detail {
      // box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.15),
      //   0px -1px 7px 0px rgba(0, 0, 0, 0.08);
      border-radius: 2px;
      margin-bottom: 24px;
    }
    .btn_wrap{
      background: #F2F2F2;
      text-align: right;
    }
  }
}
.back {
  margin-bottom: 24px;
  text-align: right;
}
.cargo_status{
  min-height:150px;
}
.cargo_detail{
  min-height:300px;
}
.contact_detail{
  min-height:200px;
}
.myBlock{
  height: 10px;
  background: #F2F2F2;
}
</style>
