<template>
  <div class="inquiry-contact">
    <div class="inquiryDetail_line"></div>
    <div class="inquiry-content">
      <div class="content-wrap">
        <div class="area">
          <div class="departure">
            <span class="text1">{{ $t("name") }}</span>
            <span class="text2">
              {{ info.name | textFormat }}
            </span>
          </div>
          <div class="name">
            <span class="text1">{{ $t("Company_n") }}</span>
            <span class="text2">
              {{ info.company | textFormat }}
            </span>
          </div>
          <div class="delivery-time">
            <span class="text1">{{ $t("telephone") }}</span>
            <span class="text2" >
              {{info.phone | phoneFormat(info.area_code)}}
            </span>
          </div>
          <div class="volume">
            <span class="text1">{{ $t("email") }}</span>
            <span class="text2" style="display: flex;width: 300px;">
              {{ info.email | textFormat }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "inquiryContact",
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  created() {
   
  }
};
</script>

<style lang="less" scoped>
.inquiry-contact {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  .inquiryDetail_line {
    border: 1px solid #ECECEC;
    // width: 100%;
    // height: 5px;
    // background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/inquiryDetailLine.png");

  }
  .inquiry-content {
    padding: 30px 69px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 22px;
    }
    .content-wrap {
      > div {
        display: flex;
        box-sizing: border-box;
        margin-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px dashed #cbcbcb;
        div {
          display: flex;
          flex-direction: column;
          span:nth-child(1) {
            margin-bottom: 20px;
          }
        }
      }
      > div:last-child {
        border-bottom: none;
      }
      
    }
  }
}
.text1 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 20px;
}
.text2 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 20px;
}
.area>div{
  width: 270px !important;
}
</style>
